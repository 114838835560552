body {
    margin: 0;
    overflow-y: auto;

    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.4em;

    transform: translateZ(0px); /* Fix paint issues in Edge && Safari H/W acceleration */

    color: #444;

    -webkit-overflow-scrolling: touch;
    -webkit-transform-style: preserve-3d;
       -moz-transform-style: preserve-3d;
        -ms-transform-style: preserve-3d;
         -o-transform-style: preserve-3d;
            transform-style: preserve-3d;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: .5em;
}

p {
    margin-top: .2em;
}

.small {
    padding: 0 20px;

    font-size: 12px;
}

h1 {
    margin-top: .2em;
    font-family: "Lora", serif;
    font-size: 40px;
    font-weight: 100;
    line-height: 1.10909;
}

h2 {
    font-size: 30px;
    font-weight: 100;
    line-height: 1.22667;
}

h3 {
    font-size: 22px;
    font-weight: 100;
    line-height: 1.36364;

    color: #bf9882;
}

h4 {
    font-size: 18px;
    font-weight: 100;
}

.intro__text {
    font-style: italic;
}

a {
    text-decoration: none;
    color: #426222;
    // color: #ef7633; not accessible
}

a:hover {
    color: #6D9F40;
    // color: #ef0033;
}

table,
thead,
tfoot,
tbody,
tr,
td {
    border: 0;
}

table {
    border-collapse: collapse;
}

td {
    padding: 0 20px;

    border-bottom: 1px solid #aaa;
}


.site-header__title-first h1,
.site-header__title-second h1 {
    margin-right: auto;
    margin-left: auto;
    text-align: center;
}

svg {
    display: block;
    margin: 0 auto;
    max-width: 564px;
}

.site-header__item--logo {
    display: block;
    width: 88vw;
    max-width: 350px;
    margin-right: auto;
    margin-left: auto;
}

// .floorplan {
//     max-width: 350px;
// }

ul.notes {
    list-style-type: none;
    padding-inline-start: 0;
    margin-top: 26px;
    margin-bottom: 30px;
    li {
        margin-top: 8px;
        margin-bottom: 8px;
        &.stared {
            margin-top: 20px;
        }
    }
}

blockquote {
}

.quote__text {
    display: inline;
    margin: 0;
    &:before,
    &:after {
        color: #bbb;
        line-height: 1em;
        font-weight: 700;
        font-family: 'Trebuchet MS', serif;
    }
    &:before {
        content: '\201C';
    }
    &:after {
        content: '\201D';
    }
}

.quote__person {
    display: inline-block;
    color: #bbb;
    font-size: 0.8em;
    line-height: 1em;
    font-weight: 100;
    font-style: italic;
    &:before {
        content: ' - ';
    }
}

.image-group {
    // border: solid #426222 1px;
}

figure {
    margin: 1em;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    img {
        height: calc(42.4vw - 4.8em);

        @media (min-width: 1000px) {
            height: 174px;
        }
        max-height: 100%;
        object-fit: cover;
    }
}
.floorplan.wide {
    img {
        height: 44vw;
        @media (min-width: 983px) {
            height: 340px;
        }
    }
}

figcaption {
    text-align: center;
    font-size: smaller;
}

.site-navigation__list {
    padding: 0;

    text-align: center;
}

.site-navigation__item {
    display: inline;
    margin: 0;

    list-style: none;
}

/*a*/.site-navigation__link {

    display: inline-block;
    padding: 10px 20px 4px;

    outline: 0;
    background-color: #fff;
}

/*a*/.site-navigation__link:hover,
/*a*/.site-navigation__link:focus {
    color: #6D9F40;
}

.site-navigation__link span {
    position: relative;

    display: block;
    padding: 0 0 5px;
}

.site-content,
.footer-content,
.copyright {
    margin-right: auto;
    margin-left: auto;
}

.site-content__main,
.site-content__aside {
    padding: 20px;
}

.banner-image {
    width: 100%;
}

a:hover .book-image {
    opacity: .6;
}

.site-footer {
    clear: both;

    .copyright {
        clear: both;
        text-align: center;
    }
}

.site-footer__main--image img {
    width: 100%;
}

.site-footer__aside {
    padding: 20px 20px;
}


.site-content__parallax {
    position: absolute;

    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;

    -webkit-perspective: 300px;
            perspective: 300px;
    scroll-behavior: smooth;
}

.page {
    position: relative;
    top: 60%;

    padding: 20px 20%;

    background-color: white;
}

.pair {
    display: flex;
    figure {
        flex-grow: 1;
    }
}

.price-card {
    flex-grow: 1;
}

.price-card__title {

}

.price-card__price-one {

}

.price-card__price-one--label {

}

.slide__one {
    z-index: 3;
}

.parallax__group {
    position: relative;

    height: 500px;
    height: 100vh;

    -webkit-transition: -webkit-transform .5s;
            transition:         transform .5s;

    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
}

.slide-one__base {
    z-index: 4;

    display: block;
    height: 100px;
    padding: 50vh 0;
}

.slide-one__near {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;

    -webkit-transform: translateZ(-300px) scale(2);
            transform: translateZ(-300px) scale(2);
    transform-origin: 50% 50%;

    background: url("../images/gallery/DSCF2973.jpg") no-repeat 50% 50%;
    background-color: orange;
}


// @media (min-width: 640px) {
//     .site-content__main,
//     .site-content__aside {
//         padding: 20px;
//     }
// }

@media (min-width: 1000px) {
    .site-content,
    .footer-content,
    .copyright {
        width: 980px;
    }

    .site-content__main,
    .site-footer__main {
        // display: inline-block;
        float: left;
        width: calc( 100% - 380px);
        padding: 20px 20px;
    }

    .site-content__aside,
    .site-footer__aside {
        display: inline-block;
        // float: left;
        width: 300px;
        padding: 20px 10px;
    }
}
